<template>
    <div>
        <h1>欢迎使用网络货运支付管理系统！</h1>
    </div>
</template>

<script>
export default {};
</script>

<style lang="stylus" scoped>
h1 {
    text-align: center;
    font-weight: bold;
    color: #666;
    font-size: 36px;
    margin: 40px 0;
}
</style>


